import React from "react";
import { TextField, InputAdornment, IconButton, useTheme } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

interface CustomSearchInputProps {
  placeholder?: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  onBlur?: () => void;
}

const CustomSearchInput: React.FC<CustomSearchInputProps> = ({
  placeholder = "Search",
  value,
  onChange,
  onFocus,
  onBlur,
}) => {
  const theme = useTheme();

  return (
    <TextField
      color="dark"
      size="small"
      variant="outlined"
      fullWidth
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton edge="start" disabled>
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
        sx: {
          borderRadius: "8px",
          backgroundColor: theme.palette.background.paper,
        },
      }}
    />
  );
};

export default CustomSearchInput;
