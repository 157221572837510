import { Box, Chip, Typography } from "@mui/material";
import { useRefinementList } from "react-instantsearch";

interface PillSelectFilterProps {
  attribute: string;
  label?: string;
  multi?: boolean;
}

function PillSelectFilter({
  attribute,
  label,
  multi = false,
}: PillSelectFilterProps) {
  const { items, refine } = useRefinementList({
    attribute,
    sortBy: ["name:asc"],
  });

  return items.length ? (
    <Box sx={{ mb: 2 }}>
      {label && (
        <Typography sx={{ mb: 1 }} fontSize={16} variant="h6">
          {label}
        </Typography>
      )}

      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
        {items.map((item) => (
          <Chip
            key={item.label}
            label={item.label}
            onClick={() => {
              if (multi) return refine(item.value);
              if (item.isRefined) return refine(item.value);
              items
                .filter((i) => i.isRefined && i.value !== item.value)
                .forEach((i) => refine(i.value));
              refine(item.value);
            }}
            variant={item.isRefined ? "filled" : "outlined"}
            color={item.isRefined ? "dark" : "default"}
            sx={{ cursor: "pointer" }}
          />
        ))}
      </Box>
    </Box>
  ) : null;
}

export default PillSelectFilter;
