import React, { useState } from "react";
import { Button, Menu } from "@mui/material";
import { useCurrentRefinements } from "react-instantsearch";

export interface FilterDefinition {
  attribute: string;
  label?: string;
  step?: number;
  multi?: boolean;
  formatValue?: (value: number | string) => string;
  component: React.ComponentType<any>;
}

interface FilterButtonProps {
  filters: FilterDefinition[];
  label: string;
  icon?: React.ReactNode;
}

function FilterButton({ filters, label, icon }: FilterButtonProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const { items } = useCurrentRefinements();
  const active = items.some((item) =>
    filters.map((f) => f.attribute).includes(item.attribute)
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        onClick={handleClick}
        variant={open || active ? "contained" : "outlined"}
        startIcon={icon}
        sx={{ borderRadius: 20, px: 3, py: 0.5, boxShadow: "none" }}
        color="dark"
      >
        {label}
      </Button>

      <Menu
        keepMounted
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          sx: {
            mt: 1,
            width: 300,
            p: 2,
            border: "1px solid #ddd",
            borderRadius: 4,
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        {filters.map(({ component: FilterComponent, attribute, ...rest }) => (
          <FilterComponent key={attribute} attribute={attribute} {...rest} />
        ))}
      </Menu>
    </>
  );
}

export default FilterButton;
