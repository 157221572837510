import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  Divider,
} from "@mui/material";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import theme from "../../../theme";
import { MenuItemSearchResult } from "../types";

const MenuItemCard: React.FC<MenuItemSearchResult> = ({
  imageUrl,
  name,
  calories,
  price,
  fat,
  carbs,
  protein,
  restaurant,
}) => {
  const cpd = calories / price;
  const cpdColor = cpd < 75 ? "error" : cpd < 100 ? "orange" : "green";
  return (
    <Card
      elevation={6}
      sx={{
        borderRadius: 2,
        overflow: "hidden",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          height: 200,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {imageUrl ? (
          <CardMedia
            component="img"
            image={imageUrl}
            alt={name}
            sx={{ height: "100%", width: "100%", objectFit: "contain" }}
          />
        ) : (
          <FastfoodIcon
            sx={{ fontSize: 100, color: theme.palette.grey[400] }}
          />
        )}
      </Box>

      <CardContent
        sx={{
          textAlign: "center",
          p: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          flexGrow: 1,
        }}
      >
        <Box>
          <Typography variant="h6" fontWeight="bold" gutterBottom>
            {name}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {restaurant.name}
          </Typography>
          <Divider
            sx={{
              mt: 1,
              mb: 4,
              width: "30%",
              mx: "auto",
              bgcolor: theme.palette.primary.main,
              height: 2,
            }}
          />
        </Box>

        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              px: 2,
            }}
          >
            <Box sx={{ textAlign: "center", flex: 1 }}>
              <Typography variant="h6" fontWeight="bold">
                {calories}
              </Typography>
              <Typography variant="body2">Cals</Typography>
            </Box>
            <Divider
              orientation="vertical"
              flexItem
              sx={{ bgcolor: theme.palette.divider, mx: 1 }}
            />
            <Box sx={{ textAlign: "center", flex: 1 }}>
              <Typography variant="h6" fontWeight="bold" color={cpdColor}>
                {cpd.toFixed(0)}
              </Typography>
              <Typography variant="body2" fontWeight="bold" color={cpdColor}>
                CPD
              </Typography>
            </Box>
            <Divider
              orientation="vertical"
              flexItem
              sx={{ bgcolor: theme.palette.divider, mx: 1 }}
            />
            <Box sx={{ textAlign: "center", flex: 1 }}>
              <Typography variant="h6" fontWeight="bold">
                ${price.toFixed(2)}
              </Typography>
              <Typography variant="body2">Price</Typography>
            </Box>
          </Box>

          <Divider
            sx={{
              my: 2,
              width: "70%",
              mx: "auto",
              bgcolor: theme.palette.dark.light,
              height: 2,
            }}
          />

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              px: 2,
              mt: 2,
            }}
          >
            <Box sx={{ textAlign: "center", flex: 1 }}>
              <Typography variant="body2" fontWeight="bold">
                {fat}g
              </Typography>
              <Typography variant="caption">Fat</Typography>
            </Box>

            <Box sx={{ textAlign: "center", flex: 1 }}>
              <Typography variant="body2" fontWeight="bold">
                {carbs}g
              </Typography>
              <Typography variant="caption">Carbs</Typography>
            </Box>

            <Box sx={{ textAlign: "center", flex: 1 }}>
              <Typography variant="body2" fontWeight="bold">
                {protein}g
              </Typography>
              <Typography variant="caption">Protein</Typography>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default MenuItemCard;
